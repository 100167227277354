
import { FETCH_BLOGSCATEGORIES_SUCCESS } from "../actions/BlogCategoryActions";

const initState = {
    blogsCategories: []
};

const blogCategoryReducer = (state = initState, action) => {
    if (action.type === FETCH_BLOGSCATEGORIES_SUCCESS) {
        return {
            ...state,
            blogsCategories: action.payload
        };
    }
    return state;
};

export default blogCategoryReducer;
