import { FETCH_PRODUCTS_SUCCESS, REMOVE_OUTSTOCK_PRODUCTS } from "../actions/productActions";

const initState = {
    products: []
};

const productReducer = (state = initState, action) => {
    if (action.type === FETCH_PRODUCTS_SUCCESS) {
        const incomingProducts = action.payload;

        // Check each incoming product
        for (const incomingProduct of incomingProducts) {
            const existingProductIndex = state.products.findIndex(
                (product) => product.pId === incomingProduct.pId
            );

            if (existingProductIndex !== -1) {
                // If the product exists, update it
                const updatedProducts = [...state.products];
                updatedProducts[existingProductIndex] = incomingProduct;
                state = {
                    ...state,
                    products: updatedProducts,
                };
            } else {
                // If the product doesn't exist, add it to the array
                state = {
                    ...state,
                    products: [...state.products, incomingProduct],
                };
            }
        }

        return state;
    }
    else if (action.type === REMOVE_OUTSTOCK_PRODUCTS) {

        const incomingProducts = action.payload;         
        const updatedProducts = state.products.filter(
            (product) => !incomingProducts.some((incomingProduct) => incomingProduct.pId === product.pId)
        );

        state = {
            ...state,
            products: updatedProducts,
        };

        return state;
    }
    return state;
};


export default productReducer;
