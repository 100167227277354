import axios from 'axios';

export const FETCH_PRODUCTS_SUCCESS = "FETCH_PRODUCTS_SUCCESS";
export const REMOVE_OUTSTOCK_PRODUCTS = "REMOVE_OUTSTOCK_PRODUCTS";

const fetchProductsSuccess = products => ({
    type: FETCH_PRODUCTS_SUCCESS,
    payload: products
});

const removeOutOfStockProducts = products => ({
    type: REMOVE_OUTSTOCK_PRODUCTS,
    payload: products
});


// fetch products
const fetchProducts = () => {
    return dispatch => {
        const fetchMoreProducts = () => {
            axios.get('/Home/Index')
                .then(response => {
                    const products = response.data;
                    if (products.length > 0) {
                        dispatch(fetchProductsSuccess(products));
                        setTimeout(fetchMoreProducts(), 5000);
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        };

        fetchMoreProducts();
    };
};

// Remove products
export const RemoveOutofStockProduct = () => {
    return dispatch => {
        const fetchOutofStockMoreProducts = () => {
            axios.get('/Home/OutOfStockProducts')
                .then(response => {
                    const products = response.data;
                    if (products.length > 0) {
                        dispatch(removeOutOfStockProducts(products));
                        setTimeout(fetchOutofStockMoreProducts(), 5000);
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        }
        fetchOutofStockMoreProducts();
    };
};

export default fetchProducts;
