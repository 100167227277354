import { FETCH_CATEGORYS_SUCCESS } from "../actions/CategoryActions";

const initState = {
    categories: []
};

const categoryReducer = (state = initState, action) => {
    if (action.type === FETCH_CATEGORYS_SUCCESS) {
        return {
            ...state,
            categories: action.payload
        };
    }
    return state;
};

export default categoryReducer;
