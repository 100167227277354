import PropTypes from "prop-types";
import React, { useEffect, Suspense, lazy } from "react";
import ScrollToTop from "./helpers/scroll-top";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { ToastProvider } from "react-toast-notifications";
import { multilanguage, loadLanguages } from "redux-multilanguage";
import { connect } from "react-redux";
import { BreadcrumbsProvider } from "react-breadcrumbs-dynamic";
import { Constants } from "./helpers/Constants";
import { PayPalScriptProvider } from '@paypal/react-paypal-js';
import ReturnPolicy from "./pages/other/ReturnPolicy";
import PrivacyPolicy from "./pages/other/PrivacyPolicy";
import FAQs from "./pages/other/FAQs";


// home pages
const HomeFashionThree = lazy(() => import("./pages/home/HomeFashionThree"));

// shop pages
const ShopGridStandard = lazy(() => import("./pages/shop/ShopGridStandard"));

// product pages
const Product = lazy(() => import("./pages/shop-product/Product"));
const ProductTabLeft = lazy(() =>
    import("./pages/shop-product/ProductTabLeft")
);

// blog pages
const BlogStandard = lazy(() => import("./pages/blog/BlogStandard"));
const BlogDetailsStandard = lazy(() => import("./pages/blog/BlogDetailsStandard"));

// other pages
const About = lazy(() => import("./pages/other/About"));
const Contact = lazy(() => import("./pages/other/Contact"));
const MyAccount = lazy(() => import("./pages/other/MyAccount"));
const LoginRegister = lazy(() => import("./pages/other/LoginRegister"));

const Cart = lazy(() => import("./pages/other/Cart"));
const Wishlist = lazy(() => import("./pages/other/Wishlist"));
const Compare = lazy(() => import("./pages/other/Compare"));
const Checkout = lazy(() => import("./pages/other/Checkout"));

const NotFound = lazy(() => import("./pages/other/NotFound"));

const App = (props) => {

    useEffect(() => {
        props.dispatch(
            loadLanguages({
                languages: {
                    en: require("./translations/english.json"),
                    fn: require("./translations/french.json"),
                    de: require("./translations/germany.json")
                }
            })
        );
    });

    return (
        <PayPalScriptProvider options={Constants.initialOptions}>

            <ToastProvider placement="bottom-left">
                <BreadcrumbsProvider>
                    <Router>
                        <ScrollToTop>
                            <Suspense
                                fallback={
                                    <div className="flone-preloader-wrapper">
                                        <div className="flone-preloader">
                                            <span></span>
                                            <span></span>
                                        </div>
                                    </div>
                                }
                            >
                                <Switch>

                                    <Route
                                        exact
                                        path={process.env.PUBLIC_URL + "/"}
                                        component={HomeFashionThree}
                                    />

                                    {/* Homepages */}

                                    <Route
                                        path={process.env.PUBLIC_URL + "/home-fashion-three"}
                                        component={HomeFashionThree}
                                    />

                                    <Route
                                        path={process.env.PUBLIC_URL + "/shop-grid-standard/:catid?"}
                                        render={(routeProps) => (
                                            <ShopGridStandard {...routeProps} key={routeProps.match.params.catid} />
                                        )}
                                    />


                                    {/* Shop product pages */}
                                    <Route
                                        path={process.env.PUBLIC_URL + "/product/:pId"}
                                        render={(routeProps) => (
                                            <Product {...routeProps} key={routeProps.match.params.pId} />
                                        )}
                                    />

                                    <Route
                                        path={process.env.PUBLIC_URL + "/product-tab-left/:id"}
                                        component={ProductTabLeft}
                                    />

                                    {/* Blog pages */}
                                    <Route
                                        path={process.env.PUBLIC_URL + "/blog-standard"}
                                        component={BlogStandard}
                                    />

                                    <Route
                                        path={process.env.PUBLIC_URL + "/blog-details-standard/:id"}
                                        render={(routeProps) => (
                                            <BlogDetailsStandard {...routeProps} key={routeProps.match.params.id} />
                                        )}
                                    />

                                    {/* Other pages */}
                                    <Route
                                        path={process.env.PUBLIC_URL + "/about"}
                                        component={About}
                                    />
                                    <Route
                                        path={process.env.PUBLIC_URL + "/return-policy"}
                                        component={ReturnPolicy}
                                    />
                                    <Route
                                        path={process.env.PUBLIC_URL + "/privacy-policy"}
                                        component={PrivacyPolicy}
                                    />
                                    <Route
                                        path={process.env.PUBLIC_URL + "/faqs"}
                                        component={FAQs}
                                    />
                                    <Route
                                        path={process.env.PUBLIC_URL + "/contact"}
                                        component={Contact}
                                    />
                                    <Route
                                        path={process.env.PUBLIC_URL + "/my-account"}
                                        component={MyAccount}
                                    />
                                    <Route
                                        path={process.env.PUBLIC_URL + "/login-register"}
                                        component={LoginRegister}
                                    />

                                    <Route
                                        path={process.env.PUBLIC_URL + "/cart"}
                                        component={Cart}
                                    />
                                    <Route
                                        path={process.env.PUBLIC_URL + "/wishlist"}
                                        component={Wishlist}
                                    />
                                    <Route
                                        path={process.env.PUBLIC_URL + "/compare"}
                                        component={Compare}
                                    />
                                    <Route
                                        path={process.env.PUBLIC_URL + "/checkout"}
                                        component={Checkout}
                                    />

                                    <Route
                                        path={process.env.PUBLIC_URL + "/not-found"}
                                        component={NotFound}
                                    />

                                    <Route exact component={NotFound} />

                                </Switch>
                            </Suspense>
                        </ScrollToTop>
                    </Router>
                </BreadcrumbsProvider>
            </ToastProvider>
        </PayPalScriptProvider>

    );
};

App.propTypes = {
    dispatch: PropTypes.func
};

export default connect()(multilanguage(App));
