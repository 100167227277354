
import { FETCH_BLOGSCOMMENTS_SUCCESS } from "../actions/blogCommentsActions";

const initState = {
    blogComments: []
};

const blogCommentsReducer = (state = initState, action) => {
    if (action.type === FETCH_BLOGSCOMMENTS_SUCCESS) {
        return {
            ...state,
            blogComments: action.payload
        };
    }
    return state;
};

export default blogCommentsReducer;
