import axios from 'axios';

export const FETCH_BLOGS_SUCCESS = "FETCH_BLOGS_SUCCESS";

const fetchBlogsSuccess = blogs => ({
    type: FETCH_BLOGS_SUCCESS,
    payload: blogs
});

// fetch products
export const fetchblogs = () => {
    return dispatch => {
        axios.get('/Blog/Index')
            .then(response => {
                dispatch(fetchBlogsSuccess(response.data));
            })
            .catch(error => {
                console.log(error);
            });
    };
};
