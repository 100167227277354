import PropTypes from "prop-types";
import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import { Constants } from "../../../helpers/Constants";
import { getDiscountPrice } from "../../../helpers/product";
const MenuCart = ({ cartData, currency, deleteFromCart }) => {
    let cartTotalPrice = 0;
    const { addToast } = useToasts();
    // if you wants to use currency COnversation use 'currency.currencyRate' instead of 1
    return (
        <div className="shopping-cart-content">
            {cartData && cartData.length > 0 ? (
                <Fragment>
                    <ul>

                        {cartData.map((single, key) => {
                            const discountedPrice = getDiscountPrice(
                                single.pSalePrice,
                                single.discount
                            );
                            const finalProductPrice = (
                                single.pSalePrice * 1
                            ).toFixed(2);
                            const finalDiscountedPrice = (
                                discountedPrice * 1
                            ).toFixed(2);

                            discountedPrice != null
                                ? (cartTotalPrice += finalDiscountedPrice * single.stock)
                                : (cartTotalPrice += finalProductPrice * single.stock);

                            return (
                                <li className="single-shopping-cart" key={key}>
                                    <div className="shopping-cart-img">
                                        <Link to={process.env.PUBLIC_URL + "/product/" + single.pId}>
                                            {single.images.length > 0 ? (
                                                <img
                                                    alt=""
                                                    src={Constants.Image_BaseUrl + single.images[0].imageName}
                                                    className="img-fluid"
                                                />
                                            ) : (
                                                <img
                                                    alt=""
                                                    src={Constants.Image_BaseUrl}
                                                    className="img-fluid"
                                                />
                                            )}
                                        </Link>
                                    </div>
                                    <div className="shopping-cart-title">
                                        <h4>
                                            <Link
                                                to={process.env.PUBLIC_URL + "/product/" + single.pId}
                                            >
                                                {" "}
                                                {single.pTitle}{" "}
                                            </Link>
                                        </h4>
                                        <h6>Qty: {single.stock}</h6>
                                        <span>
                                            {discountedPrice !== null
                                                ? currency.currencySymbol + finalDiscountedPrice
                                                : currency.currencySymbol + finalProductPrice}
                                        </span>
                                        {single.selectedProductColor &&
                                            single.selectedProductSize ? (
                                            <div className="cart-item-variation">
                                                <span>Color: {single.selectedProductColor}</span>
                                                <span>Size: {single.selectedProductSize}</span>
                                            </div>
                                        ) : (
                                            ""
                                        )}
                                    </div>
                                    <div className="shopping-cart-delete">
                                        <button onClick={() => deleteFromCart(single, addToast)}>
                                            <i className="fa fa-times-circle" />
                                        </button>
                                    </div>
                                </li>
                            );
                        })}
                    </ul>
                    <div className="shopping-cart-total">
                        <h4>
                            Total :{" "}
                            <span className="shop-total">
                                {currency.currencySymbol + cartTotalPrice.toFixed(2)}
                            </span>
                        </h4>
                    </div>
                    <div className="shopping-cart-btn btn-hover text-center">
                        <Link className="default-btn" to={process.env.PUBLIC_URL + "/cart"}>
                            view cart
                        </Link>
                        <Link
                            className="default-btn"
                            to={process.env.PUBLIC_URL + "/checkout"}
                        >
                            checkout
                        </Link>
                    </div>
                </Fragment>
            ) : (
                <p className="text-center">No items added to cart</p>
            )}
        </div>
    );
};

MenuCart.propTypes = {
    cartData: PropTypes.array,
    currency: PropTypes.object,
    deleteFromCart: PropTypes.func
};

export default MenuCart;
