import axios from 'axios';

export const FETCH_BLOGSCATEGORIES_SUCCESS = "FETCH_BLOGSCATEGORIES_SUCCESS";

const fetchblogsCategoriesSuccess = blogsCategories => ({
    type: FETCH_BLOGSCATEGORIES_SUCCESS,
    payload: blogsCategories
});

// fetch products
export const fetchblogsCategories = () => {
    return dispatch => {
        axios.get('/BlogCategory/Index')
            .then(response => {
                dispatch(fetchblogsCategoriesSuccess(response.data));
            })
            .catch(error => {
                console.log(error);
            });
    };
};
