import { SET_CURRENCY } from "../actions/currencyActions";

const initState = {
    currencySymbol: "$",
    currencyName: "USD",
    currencyRate: 1
};

// i case i wants to restore conversation Rate
// i have to replace currencyRate from 1 to action.payload.currencyRate
// with this i will use currency Rate comming from CurrencyAction.

const currencyReducer = (state = initState, action) => {
    if (action.type === SET_CURRENCY) {
        const currencyName = action.payload.currencyName;
        debugger;
        if (currencyName === "USD") {
            return {
                ...state,
                currencySymbol: "$",
                currencyRate: 1,
                currencyName
            };
        }
        if (currencyName === "EUR") {
            return {
                ...state,
                currencySymbol: "€",
                currencyRate: 1,
                currencyName
            };
        }
        if (currencyName === "GBP") {
            return {
                ...state,
                currencySymbol: "£",
                currencyRate: 1,
                currencyName
            };
        }
    }

    return state;
};

export default currencyReducer;
