import axios from 'axios';

export const FETCH_BLOGSCOMMENTS_SUCCESS = "FETCH_BLOGSCOMMENTS_SUCCESS";

const fetchBlogsCommentsSuccess = blogComments => ({
    type: FETCH_BLOGSCOMMENTS_SUCCESS,
    payload: blogComments
});

// fetch products
export const fetchblogsComments = () => {
    return dispatch => {
        axios.get('/Blog/Comments')
            .then(response => {
                dispatch(fetchBlogsCommentsSuccess(response.data));
            })
            .catch(error => {
                console.log(error);
            });
    };
};
