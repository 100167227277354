import PropTypes from "prop-types";
import React, { Fragment } from "react";
import MetaTags from "react-meta-tags";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import LayoutOne from "../../layouts/LayoutOne";
import Breadcrumb from "../../wrappers/breadcrumb/Breadcrumb";
import SectionTitleWithText from "../../components/section-title/SectionTitleWithText";
import BannerOne from "../../wrappers/banner/BannerOne";
import TextGridOne from "../../wrappers/text-grid/TextGridOne";
import FunFactOne from "../../wrappers/fun-fact/FunFactOne";
import TeamMemberOne from "../../wrappers/team-member/TeamMemberOne";
import BrandLogoSliderOne from "../../wrappers/brand-logo/BrandLogoSliderOne";

const ReturnPolicy = ({ location }) => {
    const { pathname } = location;

    return (
        <Fragment>
            <MetaTags>
                <title>NZ HUB | Return Policy</title>
                <meta
                    name="description"
                    content="spa pool covers, outdoor sauna nz, pool covers nz, saunas nz, home sauna nz, spa covers nz, sauna for sale nz, spa pool covers auckland, spa pool covers nz, permanent gazebo nz, portable sauna nz, pool pumps nz, swimming pool covers nz, buy sauna nz, above ground swimming pool nz, pool covers auckland, spa pool covers for sale, outdoor gazebo nz, spa pool covers christchurch, indoor sauna nz, spa pool covers tauranga, sauna nz for sale, spa pool pump, cheap spa pool covers nz, outdoor sauna for sale nz, pool covers christchurch, spa covers auckland, swimming pool pumps nz, pools for sale nz, spa pool lid, spa lids nz, replacement spa pool covers nz, spa pool lids nz, cheap gazebo nz, sauna for sale auckland, buy sauna auckland, sauna nz buy, swimming pool covers auckland, spa pool pumps nz, buy pool nz, used spa pools for sale nz, sand filter pump nz, second hand spa pools for sale, used spa pool for sale, bestway pool pumps nz, intex sand filter pump nz, new zealand sauna, trade me pools"
                />
            </MetaTags>
            <BreadcrumbsItem to={process.env.PUBLIC_URL + "/"}>Home</BreadcrumbsItem>
            <BreadcrumbsItem to={process.env.PUBLIC_URL + pathname}>
                Return Policy
            </BreadcrumbsItem>
            <LayoutOne headerTop="visible">
                {/* breadcrumb */}
                <Breadcrumb />
                <div style={{ padding: '100px' }} >

                    <h1>Returns And Warranty</h1>

                    <h3>Returns</h3>

                    <p>
                        If the item arrived faulty or did not work as intended, we will arrange for the repair or replacement
                        of your item after it has been returned (at our cost) and tested. </p>
                    <p>

                        We do have 99 days warranty on all of our products if not mentioned. </p>
                    <p>

                        If product is faulty or damaged please allow us to give the best possible replacement for your product,
                        if we are not able to provide you the replacement than we can process a refund for you.  </p>
                    <p>

                        We make every effort to make sure our product descriptions are as detailed as possible.
                        Please choose carefully as we are unable to exchange product once you've received your
                        order unless we have made a genuine mistake in our description (and yes, we are human)
                        or where your request to exchange is covered by the Consumers Guarantees Act or Fair Trading Act. </p>
                    <p>

                        If you’ve changed your mind, we will accept a Return (at the buyer’s cost) within 3 days
                        provided the item is unopened, unused and in a mint re-sellable condition.  </p>

                    <h3> Warranty </h3>

                    <p>
                        NZHUB warranty policy covers our products for 99 Days from the delivery date.
                        Warranty claims cover any fault, defect or failure occurring as a result of the manufacturing process.
                        Damage or faults occurring as a result of misuse, accident, or wear and tear is not covered by our warranty policy.
                    </p>
                    <p>  If you feel that your purchase has a fault that is covered under warranty please contact us,
                        including as much detail as possible (including a photo of the issue if possible or applicable)
                        so that we can assess the problem and advise you of a solution. This could be either a repair,
                        replacement or refund. We reserve the right to provide the best possible solution based on availability of stock.</p>
                </div>
            </LayoutOne>
        </Fragment>
    );
};

ReturnPolicy.propTypes = {
    location: PropTypes.object
};

export default ReturnPolicy;
