import currencyReducer from "./currencyReducer";
import productReducer from "./productReducer";
import categoryReducer from "./CategoryReducer";
import blogCategoryReducer from "./blogCategoryReducer";
import cartReducer from "./cartReducer";
import ShippingReducer from "./ShippingReducers";
import wishlistReducer from "./wishlistReducer";
import compareReducer from "./compareReducer";
import blogReducer from "./blogReducer";
import blogCommentsReducer from "./blogCommentsReducer";
import { combineReducers } from "redux";
import { createMultilanguageReducer } from "redux-multilanguage";

const rootReducer = combineReducers({
    multilanguage: createMultilanguageReducer({ currentLanguageCode: "en" }),
    currencyData: currencyReducer,
    productData: productReducer,
    cartData: cartReducer,
    wishlistData: wishlistReducer,
    compareData: compareReducer,
    categoryData: categoryReducer,
    blogData: blogReducer,
    blogCategoryData: blogCategoryReducer,
    blogCommentsData: blogCommentsReducer,
    shipping: ShippingReducer
});

export default rootReducer;
