import PropTypes from "prop-types";
import React, { Fragment } from "react";
import MetaTags from "react-meta-tags";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import LayoutOne from "../../layouts/LayoutOne";
import Breadcrumb from "../../wrappers/breadcrumb/Breadcrumb";
import SectionTitleWithText from "../../components/section-title/SectionTitleWithText";
import BannerOne from "../../wrappers/banner/BannerOne";
import TextGridOne from "../../wrappers/text-grid/TextGridOne";
import FunFactOne from "../../wrappers/fun-fact/FunFactOne";
import TeamMemberOne from "../../wrappers/team-member/TeamMemberOne";
import BrandLogoSliderOne from "../../wrappers/brand-logo/BrandLogoSliderOne";

const FAQs = ({ location }) => {
    const { pathname } = location;

    return (
        <Fragment>
            <MetaTags>
                <title>NZ HUB | FAQs</title>
                <meta
                    name="description"
                    content="spa pool covers, outdoor sauna nz, pool covers nz, saunas nz, home sauna nz, spa covers nz, sauna for sale nz, spa pool covers auckland, spa pool covers nz, permanent gazebo nz, portable sauna nz, pool pumps nz, swimming pool covers nz, buy sauna nz, above ground swimming pool nz, pool covers auckland, spa pool covers for sale, outdoor gazebo nz, spa pool covers christchurch, indoor sauna nz, spa pool covers tauranga, sauna nz for sale, spa pool pump, cheap spa pool covers nz, outdoor sauna for sale nz, pool covers christchurch, spa covers auckland, swimming pool pumps nz, pools for sale nz, spa pool lid, spa lids nz, replacement spa pool covers nz, spa pool lids nz, cheap gazebo nz, sauna for sale auckland, buy sauna auckland, sauna nz buy, swimming pool covers auckland, spa pool pumps nz, buy pool nz, used spa pools for sale nz, sand filter pump nz, second hand spa pools for sale, used spa pool for sale, bestway pool pumps nz, intex sand filter pump nz, new zealand sauna, trade me pools"
                />
            </MetaTags>
            <BreadcrumbsItem to={process.env.PUBLIC_URL + "/"}>Home</BreadcrumbsItem>
            <BreadcrumbsItem to={process.env.PUBLIC_URL + pathname}>
                FAQs
            </BreadcrumbsItem>
            <LayoutOne headerTop="visible">
                {/* breadcrumb */}
                <Breadcrumb />

                {/* section title with text */}
                <SectionTitleWithText spaceTopClass="pt-100" spaceBottomClass="pb-95" />

                {/* banner */}
                <BannerOne spaceBottomClass="pb-70" />

                {/* text grid */}
                <TextGridOne spaceBottomClass="pb-70" />

                {/* fun fact */}
                <FunFactOne
                    spaceTopClass="pt-100"
                    spaceBottomClass="pb-70"
                    bgClass="bg-gray-3"
                />

                {/* team member */}
                <TeamMemberOne spaceTopClass="pt-95" spaceBottomClass="pb-70" />

                {/* brand logo slider */}
                <BrandLogoSliderOne spaceBottomClass="pb-70" />

            </LayoutOne>
        </Fragment>
    );
};

FAQs.propTypes = {
    location: PropTypes.object
};

export default FAQs;
