import { Add_SHIPPING_SUCCESS} from "../actions/ShippingAction";

const initState = {
    Shipping: {}
};

const ShippingReducer = (state = initState, action) => {
    if (action.type === Add_SHIPPING_SUCCESS) {
        return {
            ...state,
            Shipping: action.payload
        };
    }
    return state;
};

export default ShippingReducer;
