import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from "react";
import ReactDOM from "react-dom";
import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { save, load } from "redux-localstorage-simple";
import { Provider } from "react-redux";
import fetchProducts,{  RemoveOutofStockProduct } from "./redux/actions/productActions";
import { fetchCategory } from "./redux/actions/CategoryActions";
import { fetchblogs } from "./redux/actions/BlogActions";
import { fetchblogsCategories } from "./redux/actions/BlogCategoryActions";
import { fetchblogsComments } from "./redux/actions/blogCommentsActions";
import rootReducer from "./redux/reducers/rootReducer";
import App from "./App";
import "./assets/scss/style.scss";
import * as serviceWorker from "./serviceWorker";
import { composeWithDevTools } from "redux-devtools-extension";

export const store = createStore(
    rootReducer,
    load(),
    composeWithDevTools(applyMiddleware(thunk, save()))
);

 
store.dispatch(fetchCategory());
store.dispatch(fetchblogs());
store.dispatch(fetchblogsCategories());
store.dispatch(fetchblogsComments());
store.dispatch(RemoveOutofStockProduct());
store.dispatch(fetchProducts());


ReactDOM.render(
    <Provider store={store}>
        <App />
    </Provider>,
    document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
