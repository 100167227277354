import PropTypes from "prop-types";
import React from "react";

const SectionTitleWithText = ({ spaceTopClass, spaceBottomClass }) => {
    return (
        <div
            className={`welcome-area ${spaceTopClass ? spaceTopClass : ""} ${spaceBottomClass ? spaceBottomClass : ""
                }`}
        >
            <div className="container">
                <div className="welcome-content text-center">
                    <h5>Who Are We</h5>
                    <h1>Welcome To NZ HUB</h1>
                    <div>
                        <p>
                            Dear Valued Shoppers,
                        </p>
                        <p>
                            A warm and hearty welcome to NZhub, your go-to online destination for an exceptional shopping experience! At NZhub, we believe in simplifying your daily life by bringing you a curated selection of high-quality, everyday products that cater to your diverse needs. </p>

                        <h2>
                            Why Choose NZhub?
                        </h2>

                        <h3> 🌐 Wide Variety: </h3> <p> Discover a diverse range of daily usage products, from household essentials to trendy gadgets, all under one virtual roof.</p>

                        <h3>  🌈 Quality Assurance:  </h3> <p> We are committed to offering products that meet the highest standards of quality. Shop with confidence, knowing that each item has been carefully selected for your satisfaction.</p>

                        <h3>🚚 Fast and Reliable Delivery:  </h3> <p> Your time is precious, and we understand that. Enjoy swift and dependable delivery services, ensuring your purchases reach you when you need them.</p>

                        <h3> 🛒 User-Friendly Interface:  </h3> <p> Our website is designed with you in mind. Navigate seamlessly, explore effortlessly, and make your purchases with just a few clicks.</p>

                        <h3>  🤝 Customer Satisfaction:  </h3> <p> Your happiness is our priority. Our dedicated customer support team is here to assist you with any queries or concerns you may have. We value your feedback and strive to exceed your expectations.</p>

                        <h3>  🎁 Exciting Offers:  </h3> <p> Take advantage of exclusive deals, discounts, and special offers. Because at NZhub, we believe in making your shopping experience not only convenient but also affordable.</p>
                        <p>
                            Thank you for choosing NZhub as your preferred E-commerce destination. Whether you're a seasoned online shopper or exploring the digital marketplace for the first time, we're thrilled to have you with us on this exciting journey.</p>

                        <p> <b>
                            Happy Shopping!
                        </b> </p>

                    </div>
                </div>
            </div>
        </div>
    );
};

SectionTitleWithText.propTypes = {
    spaceBottomClass: PropTypes.string,
    spaceTopClass: PropTypes.string
};

export default SectionTitleWithText;
