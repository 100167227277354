import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { multilanguage } from "redux-multilanguage";

const NavMenu = ({ categories, strings, menuWhiteClass, sidebarMenu }) => {

    let CenterIndex = 0;
    let FirstHalf = [];
    let SecondHalf = [];

    if (categories && categories.length !== 0) {

        CenterIndex = Math.ceil(categories.length / 2);
        FirstHalf = categories.slice(0, CenterIndex);
        SecondHalf = categories.slice(CenterIndex);
    }

    return (
        <div
            className={` ${sidebarMenu
                ? "sidebar-menu"
                : `main-menu ${menuWhiteClass ? menuWhiteClass : ""}`
                } `}
        >
            <nav>
                <ul>
                    <li>
                        <Link to={process.env.PUBLIC_URL + "/"}>
                            {strings["home"]}
                        </Link>
                    </li>
                    <li>
                        <Link to={process.env.PUBLIC_URL + "/shop-grid-standard"}>
                            {" Categories"}
                            {sidebarMenu ? (
                                <span>
                                    <i className="fa fa-angle-right"></i>
                                </span>
                            ) : (
                                <i className="fa fa-angle-down" />
                            )}
                        </Link>
                        <ul className="mega-menu">
                            <li>
                                <ul className="ml-10 mr-10" >
                                    {
                                        FirstHalf.map(cat => (
                                            <li className="mega-menu-title">
                                                <Link to={process.env.PUBLIC_URL + "/shop-grid-standard/" + cat.catId}>
                                                    {cat.catName}
                                                </Link>
                                            </li>
                                        ))
                                    }
                                </ul>
                            </li>
                            <li>
                                <ul>
                                    {
                                        SecondHalf.map(cat => (
                                            <li className="mega-menu-title">
                                                <Link to={process.env.PUBLIC_URL + "/shop-grid-standard/" + cat.catId}>
                                                    {cat.catName}
                                                </Link>
                                            </li>
                                        ))
                                    }


                                </ul>
                            </li>
                            <li>
                                <ul>
                                    <li className="mega-menu-img">
                                        <Link to={process.env.PUBLIC_URL + "/shop-grid-standard"}>
                                            <img
                                                src={
                                                    process.env.PUBLIC_URL +
                                                    "/assets/img/banner/banner-12.jpg"
                                                }
                                                alt=""
                                            />
                                        </Link>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </li>
  
                    <li>
                        <Link to={process.env.PUBLIC_URL + "/about"}>
                            {strings["about_us"]}
                        </Link>
                    </li>
                    <li>
                        <Link to={process.env.PUBLIC_URL + "/blog-standard"}>
                            {strings["blog"]}
                        </Link>

                    </li>
                    <li>
                        <Link to={process.env.PUBLIC_URL + "/contact"}>
                            {strings["contact_us"]}
                        </Link>
                    </li>
                </ul>
            </nav>
        </div>
    );
};

NavMenu.propTypes = {
    menuWhiteClass: PropTypes.string,
    sidebarMenu: PropTypes.bool,
    strings: PropTypes.object,
    Categories: PropTypes.array
};


export default multilanguage(NavMenu);
