import axios from 'axios';

// get products
export const getProducts = (categories, products, category, type, limit) => {
   
    let catIDs = [];
    if (category !== undefined && category !== 0 && category !== '') {
       
        const SelectedCategory = categories.find((cat) => cat.catId == category);
        console.log(SelectedCategory);

        const AllCategoriesUnderSelectedCategories = categories.filter(item => item.catPath.includes(SelectedCategory.catName));

        catIDs = AllCategoriesUnderSelectedCategories !== undefined ? AllCategoriesUnderSelectedCategories.map(item => item.catId) : [];
         

        const finalProducts = catIDs.length
            ? products.filter(
                product => catIDs.includes(product.categoryID)
            )
            : products;

        if (type && type === "new") {
            const newProducts = finalProducts.filter(single => single.new);
            return newProducts.slice(0, limit ? limit : newProducts.length);
        }

        if (type && type === "bestSeller") {
            return finalProducts
                .sort((a, b) => {
                    return b.saleCount - a.saleCount;
                })
                .slice(0, limit ? limit : finalProducts.length);
        }

        if (type && type === "saleItems") {
            const saleItems = finalProducts.filter(
                single => single.discount && single.discount > 0
            );
            return saleItems.slice(0, limit ? limit : saleItems.length);
        }

        return finalProducts.slice(0, limit ? limit : finalProducts.length);


    }

    const finalProducts = products;

    if (type && type === "new") {
        const newProducts = finalProducts.filter(single => single.new);
        return newProducts.slice(0, limit ? limit : newProducts.length);
    }

    if (type && type === "bestSeller") {
        return finalProducts
            .sort((a, b) => {
                return b.saleCount - a.saleCount;
            })
            .slice(0, limit ? limit : finalProducts.length);
    }

    if (type && type === "saleItems") {
        const saleItems = finalProducts.filter(
            single => single.discount && single.discount > 0
        );
        return saleItems.slice(0, limit ? limit : saleItems.length);
    }

    return finalProducts.slice(0, limit ? limit : finalProducts.length);
};

// get products
export const getSearchProducts = (categories, products, category, type, limit,query) => {
     
    let catIDs = [];
    if ((category !== undefined && category !== 0 && category !== '') || (query !== undefined && query !== 0 && query !== '') ) {
    
        const SelectedCategory = categories.find((cat) => cat.catId == category);

        if (SelectedCategory !== undefined) {
            const AllCategoriesUnderSelectedCategories = categories.filter(item => item.catPath.includes(SelectedCategory.catName));

            catIDs = AllCategoriesUnderSelectedCategories !== undefined ? AllCategoriesUnderSelectedCategories.map(item => item.catId) : [];
        }
  
        const finalProducts = catIDs.length
            ? products.filter(
                product => catIDs.includes(product.categoryID) 
            )
            : products;

        const finalproducts2 = query.length 
            ? products.filter(
                product => product.pTitle.toLowerCase().includes(query.toLowerCase())
            )
            : products;
 
        if (type && type === "new") {
            const newProducts = finalproducts2.filter(single => single.new);
            return newProducts.slice(0, limit ? limit : newProducts.length);
        }

        if (type && type === "bestSeller") {
            return finalproducts2
                .sort((a, b) => {
                    return b.saleCount - a.saleCount;
                })
                .slice(0, limit ? limit : finalproducts2.length);
        }

        if (type && type === "saleItems") {
            const saleItems = finalproducts2.filter(
                single => single.discount && single.discount > 0
            );
            return saleItems.slice(0, limit ? limit : saleItems.length);
        }

        return finalproducts2.slice(0, limit ? limit : finalproducts2.length);

    }

    const finalProducts = products;

    if (type && type === "new") {
        const newProducts = finalProducts.filter(single => single.new);
        return newProducts.slice(0, limit ? limit : newProducts.length);
    }

    if (type && type === "bestSeller") {
        return finalProducts
            .sort((a, b) => {
                return b.saleCount - a.saleCount;
            })
            .slice(0, limit ? limit : finalProducts.length);
    }

    if (type && type === "saleItems") {
        const saleItems = finalProducts.filter(
            single => single.discount && single.discount > 0
        );
        return saleItems.slice(0, limit ? limit : saleItems.length);
    }

    return finalProducts.slice(0, limit ? limit : finalProducts.length);
};


// get product discount price
export const getDiscountPrice = (price, discount) => {
    return discount && discount > 0 ? price - price * (discount / 100) : null;
};

// get product cart quantity
export const getProductCartQuantity = (cartItems, product, color, size) => {
    let productInCart = cartItems.filter(
        single =>
            single.pId === product.pId &&
            (single.selectedProductColor
                ? single.selectedProductColor === color
                : true) &&
            (single.selectedProductSize ? single.selectedProductSize === size : true)
    )[0];
    if (cartItems.length >= 1 && productInCart) {
        if (product.variation) {
            return cartItems.filter(
                single =>
                    single.id === product.pId &&
                    single.selectedProductColor === color &&
                    single.selectedProductSize === size
            )[0].quantity;
        } else {
            return cartItems.filter(single => product.pId === single.pId)[0].stock;
        }
    } else {
        return 0;
    }
};

//get products based on category
export const getSortedProducts = (products, sortType, sortValue) => {
    
    if (products && sortType && sortValue) {
        if (sortType === "category") {
            return products.filter(
                single => single.categoryID === sortValue)

        }
        if (sortType === "tag") {
            return products.filter(
                product => product.tag.filter(single => single === sortValue)[0]
            );
        }
        if (sortType === "color") {
            return products.filter(
                product =>
                    product.variation &&
                    product.variation.filter(single => single.color === sortValue)[0]
            );
        }
        if (sortType === "size") {
            return products.filter(
                product =>
                    product.variation &&
                    product.variation.filter(
                        single => single.size.filter(single => single.name === sortValue)[0]
                    )[0]
            );
        }
        if (sortType === "filterSort") {
            let sortProducts = [...products];
            if (sortValue === "default") {
                return sortProducts;
            }
            if (sortValue === "priceHighToLow") {
                return sortProducts.sort((a, b) => {
                    return b.pSalePrice - a.pSalePrice;
                });
            }
            if (sortValue === "priceLowToHigh") {
                return sortProducts.sort((a, b) => {
                    return a.pSalePrice - b.pSalePrice;
                });
            }
        }
    }
    return products;
};

// get individual element
const getIndividualItemArray = array => {
    let individualItemArray = array.filter(function (v, i, self) {
        return i === self.indexOf(v);
    });
    return individualItemArray;
};

// get individual categories
export const getIndividualCategories = () => {
    let productCategories = [];
    axios.get('/Category/GetFeatureCategories').then(response => {
        response.data.map(cat => productCategories.push(cat));
        
        return productCategories;
    });
};

// get individual tags
export const getIndividualTags = products => {
    let productTags = [];
    products &&
        products.map(product => {
            return (
                product.tag &&
                product.tag.map(single => {
                    return productTags.push(single);
                })
            );
        });
    const individualProductTags = getIndividualItemArray(productTags);
    return individualProductTags;
};

// get individual colors
export const getIndividualColors = products => {
    let productColors = [];
    products &&
        products.map(product => {
            return (
                product.variation &&
                product.variation.map(single => {
                    return productColors.push(single.color);
                })
            );
        });
    const individualProductColors = getIndividualItemArray(productColors);
    return individualProductColors;
};

// get individual sizes
export const getProductsIndividualSizes = products => {
    let productSizes = [];
    products &&
        products.map(product => {
            return (
                product.variation &&
                product.variation.map(single => {
                    return single.size.map(single => {
                        return productSizes.push(single.name);
                    });
                })
            );
        });
    const individualProductSizes = getIndividualItemArray(productSizes);
    return individualProductSizes;
};

// get product individual sizes
export const getIndividualSizes = product => {
    let productSizes = [];
    product.variation &&
        product.variation.map(singleVariation => {
            return (
                singleVariation.size &&
                singleVariation.size.map(singleSize => {
                    return productSizes.push(singleSize.name);
                })
            );
        });
    const individualSizes = getIndividualItemArray(productSizes);
    return individualSizes;
};

export const setActiveSort = e => {
    const filterButtons = document.querySelectorAll(
        ".sidebar-widget-list-left button, .sidebar-widget-tag button, .product-filter button"
    );
    filterButtons.forEach(item => {
        item.classList.remove("active");
    });
    e.currentTarget.classList.add("active");
};

export const setActiveLayout = e => {
    const gridSwitchBtn = document.querySelectorAll(".shop-tab button");
    gridSwitchBtn.forEach(item => {
        item.classList.remove("active");
    });
    e.currentTarget.classList.add("active");
};

export const toggleShopTopFilter = e => {
    const shopTopFilterWrapper = document.querySelector(
        "#product-filter-wrapper"
    );
    shopTopFilterWrapper.classList.toggle("active");
    if (shopTopFilterWrapper.style.height) {
        shopTopFilterWrapper.style.height = null;
    } else {
        shopTopFilterWrapper.style.height =
            shopTopFilterWrapper.scrollHeight + "px";
    }
    e.currentTarget.classList.toggle("active");
};
