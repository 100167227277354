import axios from 'axios';

export const FETCH_CATEGORYS_SUCCESS = "FETCH_CATEGORY_SUCCESS";

const fetchCategoriesSuccess = categories => ({
    type: FETCH_CATEGORYS_SUCCESS,
    payload: categories
});

// fetch products
export const fetchCategory = () => {
    return dispatch => {
        axios.get('/Category/GetAllCategories')
            .then(response => {
                dispatch(fetchCategoriesSuccess(response.data));
            })
            .catch(error => {
                console.log(error)
            });
    };
};
